<script lang="ts">
	import { onMount } from 'svelte'

	// Configuration options for the InView component
	interface InViewOptions {
		rootMargin?: string // Margin around the root element
		threshold?: number | number[] // Visibility threshold to trigger callbacks
		enabled?: boolean // Whether intersection observing is active
		class?: string // CSS classes to apply to wrapper element
		once?: boolean // If true, stops observing after first intersection
		root?: Element | null // The root element for intersection checking
		id?: string
		children?: (props: { isInView: boolean }) => any // Render function for children
		onenter?: () => void
		onleave?: () => void
	}

	// Initialize props with TypeScript type checking
	const props: InViewOptions = $props()

	// Destructure props with default values
	const {
		rootMargin = '0px',
		threshold = 0,
		enabled = true,
		class: className = '',
		once = false,
		root = null,
		id,
		children,
		onenter,
		onleave,
		...rest
	} = props

	// Reactive state for tracking visibility and element reference
	let isInView = $state(false)
	let element = $state<HTMLDivElement | null>(null)

	// IntersectionObserver instance
	let observer: IntersectionObserver

	function createObserver() {
		if (!enabled || !element) return

		observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					// Update visibility state
					isInView = entry.isIntersecting

					// Execute callback props directly
					if (entry.isIntersecting) {
						onenter?.()
					} else {
						onleave?.()
					}

					// If 'once' is true and element is visible, disconnect observer
					if (once && entry.isIntersecting && observer) {
						observer.disconnect()
					}
				})
			},
			{
				root,
				rootMargin,
				threshold,
			},
		)

		// Start observing the target element
		observer.observe(element)
	}

	// Effect to handle observer lifecycle based on enabled state
	$effect(() => {
		if (enabled && element && !observer) {
			createObserver()
		} else if (!enabled && observer) {
			observer.disconnect()
			observer = undefined
		}
	})

	// Cleanup observer on component unmount
	onMount(() => {
		return () => {
			if (observer) {
				observer.disconnect()
			}
		}
	})
</script>

<!-- Wrapper div that binds to element reference and renders children -->
<div {id} data-block-id={id} bind:this={element} class={className} {...rest}>
	{@render children?.({ isInView })}
</div>
